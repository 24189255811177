import React, { useContext, useEffect, useState } from 'react';
import '../App.css';
import axios from 'axios';
import useAuth from '../UseAuth';
import { StateContext } from '../components/StateContainer';
import { Link, useParams } from 'react-router-dom';
import Logout from './components/logout/Logout';
import { SiSessionize as IconSessione } from 'react-icons/si';
import { IoMdMail as IconMail } from 'react-icons/io';
import { MdOutlineAttachFile as IconaAttachment } from 'react-icons/md';

import Moment from 'react-moment';

function AnagraficaPartecipanteHostess() {
  const {
    loading,
    setLoading,
    error,
    setError,
    setPageTitle,
    eventCode,
    autologinCode,
    AllUserData,
    token,
  } = useContext(StateContext);

  const { idSessione, idPartecipante } = useParams();

  const [isError, setIsError] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login } = useAuth();

  const [mezzoScelto, setMezzoScelto] = useState('');
  const [datiAggiornati, setDatiAggiornati] = useState(false);
  const [dettagliPartecipazione, setDettagliPartecipazione] = useState([]);
  const [eventDetails, setEventDetails] = useState([]);

  useEffect(() => {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_PUBLIC_URL}/evento/hostess/detail`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: {
        eventCode: eventCode,
      },
    })
      .then((response) => {
        const eventDetails = response.data.response;
        setEventDetails(eventDetails);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    setPageTitle('Anagrafica completa');
  }, []);

  useEffect(() => {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/anagrafica-completa-partecipante-hostess/detail`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      data: {
        eventCode: eventCode,
        idPartecipante: idPartecipante,
      },
    })
      .then((response) => {
        setDettagliPartecipazione(response.data.response);
        console.log(response.data.response.anagrafica);
      })
      .catch((error) => {
        if (error.response.status == '401') {
          window.location.replace(
            `/login?eventCode=${eventCode}&autologinCode=${autologinCode}`
          );
        }
      });
  }, [datiAggiornati]);

  const AggiornaDatiPartecipazioneUtente = () => {
    setDatiAggiornati(false);
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/hostess/partecipazione/update`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      data: {
        eventCode: eventCode,
        datiPartecipazione: dettagliPartecipazione,
        idPartecipante: idPartecipante,
      },
    })
      .then((response) => {
        // window.location.replace(
        //   `/sessioni/${idSessione}/accomodation?eventCode=${eventCode}&autologinCode=${autologinCode}`
        // );
        console.log(response);
        setDatiAggiornati(true);
      })
      .catch((error) => {
        if (error.response.status == '401') {
          window.location.replace(
            `/login?eventCode=${eventCode}&autologinCode=${autologinCode}`
          );
        }
      });
  };

  const formatDateToYYYYMMDD = (date) => {
    if (!date || !date.includes('-')) return ''; // Return empty if not valid
    const [day, month, year] = date.split('-');
    return `${year}-${month}-${day}`;
  };
  const handleChange = (e, section) => {
    const { id, value, type } = e.target;

    let formattedValue = value;

    // If the input is a date, convert from YYYY-MM-DD to DD-MM-YYYY
    if (
      type === 'date' &&
      (id === 'scadenzaPatente' || id === 'scadenzaCartaIdentita')
    ) {
      const [year, month, day] = value.split('-');
      formattedValue = `${day}-${month}-${year}`;
    }

    setDettagliPartecipazione((prev) => ({
      ...prev,
      [section]: {
        ...prev[section],
        [id]: formattedValue,
      },
    }));
  };

  return (
    <div
      className="account-pages bg-grey-stellantis"
      style={{
        height: 'auto',
        minHeight: '100vh',
      }}
    >
      <div className="container">
        <div
          className="row"
          style={{
            // minHeight: "100vh",
            height: 'auto',
          }}
        >
          <div
            className="col-12 p-0"
            style={{ height: '100px', marginBottom: '60px' }}
          >
            <div
              className="box-settings-sessioni position-relative"
              style={{ backgroundColor: eventDetails.coloreSecondarioEvento }}
            >
              <Logout />
              <img src={`${eventDetails.logoEvento}`} alt="" />
              <div className="container-nome-evento-sessioni">
                <div
                  className="box-nome-evento-anagrafica-completa"
                  style={{ backgroundColor: eventDetails.colorePrimarioEvento }}
                >
                  {eventDetails.nomeEvento}
                </div>
              </div>
            </div>
          </div>

          <div className="col-12" style={{ marginBottom: '30px' }}>
            <div className="card p-3 ">
              <h3
                className="text-blue-stellantis fw-bold"
                style={{ color: eventDetails.colorePrimarioEvento }}
              >
                {dettagliPartecipazione && dettagliPartecipazione.anagrafica
                  ? dettagliPartecipazione.anagrafica.nome
                  : ''}{' '}
                {dettagliPartecipazione && dettagliPartecipazione.anagrafica
                  ? dettagliPartecipazione.anagrafica.cognome
                  : ''}
              </h3>
              <p
                className="text-blue-stellantis font-size-18 fw-bold"
                style={{ color: eventDetails.colorePrimarioEvento }}
              >
                {dettagliPartecipazione && dettagliPartecipazione.anagrafica
                  ? dettagliPartecipazione.anagrafica?.['ragioneSociale']
                  : ''}
              </p>
              <div className=" my-3 p-3" style={{ border: '1px black solid' }}>
                <h4
                  className="text-blue-stellantis fw-bold d-flex align-items-center gap-2 m-0"
                  style={{ color: eventDetails.colorePrimarioEvento }}
                >
                  <IconSessione />{' '}
                  {dettagliPartecipazione.nome_sessione !== ''
                    ? dettagliPartecipazione.nome_sessione
                    : `Sessione ${dettagliPartecipazione.sessioneId}`}{' '}
                  -{' '}
                  <span className="fw-normal">
                    <Moment format="DD-MM-YYYY">
                      {dettagliPartecipazione.data_sessione}
                    </Moment>
                  </span>
                </h4>
              </div>
            </div>
          </div>

          {/* Accordion anagrafica partecipante */}
          <div className="col-12">
            <div className="accordion" id="accordionDatiPartecipazione">
              {dettagliPartecipazione && (
                <div
                  className="accordion-item mb-3 border-0"
                  style={{ borderRadius: '20px' }}
                >
                  <h2
                    className="accordion-header"
                    id={`heading-${dettagliPartecipazione.id}`}
                  >
                    <button
                      className="accordion-button accordion-button-stellantis collapsed py-4"
                      style={{
                        borderRadius: '20px',
                        backgroundColor: eventDetails.colorePrimarioEvento,
                      }}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#anagrafica-${dettagliPartecipazione.id}`}
                      aria-expanded="false"
                      aria-controls={`anagrafica-${dettagliPartecipazione.id}`}
                    >
                      <b>Anagrafica</b>
                    </button>
                  </h2>
                  <div
                    id={`anagrafica-${dettagliPartecipazione.id}`}
                    className="accordion-collapse collapse"
                    aria-labelledby={`heading-${dettagliPartecipazione.id}`}
                    data-bs-parent="#accordionDatiPartecipazione"
                  >
                    <div className="accordion-body">
                      <label className="form-label mt-3" htmlFor="nome">
                        Nome
                      </label>
                      <input
                        type="text"
                        className="form-control mb-1"
                        id="nome"
                        placeholder="Inserisci nome iscritto"
                        value={
                          dettagliPartecipazione.anagrafica &&
                          dettagliPartecipazione.anagrafica.nome
                        }
                        onChange={(e) => handleChange(e, 'anagrafica')}
                      />
                      <label className="form-label mt-3" htmlFor="cognome">
                        Cognome
                      </label>
                      <input
                        type="text"
                        className="form-control mb-1"
                        id="cognome"
                        placeholder="Inserisci cognome iscritto"
                        value={
                          dettagliPartecipazione.anagrafica &&
                          dettagliPartecipazione.anagrafica.cognome
                        }
                        onChange={(e) => handleChange(e, 'anagrafica')}
                      />

                      <label className="form-label mt-3" htmlFor="email">
                        Email
                      </label>
                      <p>
                        {dettagliPartecipazione.anagrafica &&
                          dettagliPartecipazione.anagrafica.email}
                      </p>

                      <label
                        className="form-label mt-3"
                        htmlFor="ragioneSociale"
                      >
                        Ragione Sociale:
                      </label>
                      <input
                        type="text"
                        className="form-control mb-1"
                        id="ragioneSociale"
                        placeholder="Inserisci ragione sociale iscritto"
                        value={
                          dettagliPartecipazione.anagrafica &&
                          dettagliPartecipazione.anagrafica.ragioneSociale
                        }
                        onChange={(e) => handleChange(e, 'anagrafica')}
                      />

                      <label className="form-label mt-3" htmlFor="citta">
                        Città
                      </label>
                      <input
                        type="text"
                        className="form-control mb-1"
                        id="citta"
                        placeholder="Inserisci città iscritto"
                        value={
                          dettagliPartecipazione.anagrafica &&
                          dettagliPartecipazione.anagrafica.citta
                        }
                        onChange={(e) => handleChange(e, 'anagrafica')}
                      />

                      <label className="form-label mt-3" htmlFor="provincia">
                        Provincia
                      </label>
                      <input
                        type="text"
                        className="form-control mb-1"
                        id="provincia"
                        placeholder="Inserisci provincia iscritto"
                        value={
                          dettagliPartecipazione.anagrafica &&
                          dettagliPartecipazione.anagrafica.provincia
                        }
                        onChange={(e) => handleChange(e, 'anagrafica')}
                      />

                      <label className="form-label mt-3" htmlFor="idEsterno">
                        Id Esterno
                      </label>
                      <input
                        type="text"
                        className="form-control mb-1"
                        id="idEsterno"
                        placeholder="Inserisci ID esterno iscritto"
                        value={
                          dettagliPartecipazione.anagrafica &&
                          dettagliPartecipazione.anagrafica.idEsterno
                        }
                        onChange={(e) => handleChange(e, 'anagrafica')}
                      />

                      <label
                        className="form-label mt-3"
                        htmlFor="codiceConcessionaria"
                      >
                        Codice concessionaria:
                      </label>
                      <input
                        type="text"
                        className="form-control mb-1"
                        id="codiceConcessionaria"
                        placeholder="Inserisci codice concessionaria iscritto"
                        value={
                          dettagliPartecipazione.anagrafica &&
                          dettagliPartecipazione.anagrafica.codiceConcessionaria
                        }
                        onChange={(e) => handleChange(e, 'anagrafica')}
                      />
                      <label
                        className="form-label mt-3"
                        htmlFor="numeroTelefono"
                      >
                        Numero di telefono:
                      </label>
                      <input
                        type="text"
                        className="form-control mb-1"
                        id="numeroTelefono"
                        placeholder="Inserisci numero di telefono"
                        value={
                          dettagliPartecipazione.anagrafica &&
                          dettagliPartecipazione.anagrafica.numeroTelefono
                        }
                        onChange={(e) => handleChange(e, 'anagrafica')}
                      />
                      <button
                        className="btn  w-sm waves-effect waves-light w-100 text-white mt-4 py-3"
                        onClick={AggiornaDatiPartecipazioneUtente}
                        style={{
                          backgroundColor: eventDetails.colorePrimarioEvento,
                        }}
                      >
                        Aggiorna dati
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {/* Accordion patente / identità */}

              {dettagliPartecipazione && (
                <div
                  className="accordion-item mb-3 border-0"
                  style={{ borderRadius: '20px' }}
                >
                  <h2
                    className="accordion-header"
                    id={`heading-${dettagliPartecipazione.id}`}
                  >
                    <button
                      className="accordion-button collapsed py-4"
                      style={{
                        borderRadius: '20px',
                        backgroundColor: eventDetails.colorePrimarioEvento,
                      }}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#documenti-${dettagliPartecipazione.id}`}
                      aria-expanded="false"
                      aria-controls={`documenti-${dettagliPartecipazione.id}`}
                    >
                      <b className="text-white">Patente / Carta d'identità</b>
                    </button>
                  </h2>
                  <div
                    id={`documenti-${dettagliPartecipazione.id}`}
                    className="accordion-collapse collapse"
                    aria-labelledby={`heading-${dettagliPartecipazione.id}`}
                    data-bs-parent="#accordionDatiPartecipazione"
                  >
                    <div className="accordion-body">
                      <label
                        className="form-label mt-3"
                        htmlFor="codiceCartaIdentita"
                      >
                        Codice carta d'identità:
                      </label>
                      <input
                        type="text"
                        className="form-control mb-1"
                        id="codiceCartaIdentita"
                        placeholder="Inserisci codice carta d'identità iscritto"
                        value={
                          dettagliPartecipazione.documenti &&
                          dettagliPartecipazione.documenti.codiceCartaIdentita
                        }
                        onChange={(e) => handleChange(e, 'documenti')}
                      />

                      <label
                        className="form-label mt-3"
                        htmlFor="scadenzaCartaIdentita"
                      >
                        Scadenza carta d'identita
                      </label>
                      <input
                        type="date"
                        className="form-control mb-1"
                        id="scadenzaCartaIdentita"
                        placeholder="Inserisci scadenza carta d'identità iscritto"
                        value={
                          dettagliPartecipazione.documenti &&
                          dettagliPartecipazione.documenti.scadenzaCartaIdentita
                            ? formatDateToYYYYMMDD(
                                dettagliPartecipazione.documenti
                                  .scadenzaCartaIdentita
                              )
                            : ''
                        }
                        onChange={(e) => handleChange(e, 'documenti')}
                      />
                      <label
                        className="form-label mt-3"
                        htmlFor="codicePatente"
                      >
                        Codice patente:
                      </label>
                      <input
                        type="text"
                        className="form-control mb-1"
                        id="codicePatente"
                        placeholder="Inserisci codice patente"
                        value={
                          dettagliPartecipazione.documenti &&
                          dettagliPartecipazione.documenti.codicePatente
                        }
                        onChange={(e) => handleChange(e, 'documenti')}
                      />

                      <label
                        className="form-label mt-3"
                        htmlFor="scadenzaPatente"
                      >
                        Scadenza patente
                      </label>
                      <input
                        type="date"
                        className="form-control mb-1"
                        id="scadenzaPatente"
                        placeholder="Inserisci scadenza patente iscritto"
                        value={
                          dettagliPartecipazione.documenti &&
                          dettagliPartecipazione.documenti.scadenzaPatente
                            ? formatDateToYYYYMMDD(
                                dettagliPartecipazione.documenti.scadenzaPatente
                              )
                            : ''
                        }
                        onChange={(e) => handleChange(e, 'documenti')}
                      />

                      <button
                        className="btn  w-sm waves-effect waves-light w-100 text-white mt-4 py-3"
                        onClick={AggiornaDatiPartecipazioneUtente}
                        style={{
                          backgroundColor: eventDetails.colorePrimarioEvento,
                        }}
                      >
                        Aggiorna dati
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {/* Accordion trasporti */}

              {dettagliPartecipazione && (
                <div
                  className="accordion-item mb-3 border-0"
                  style={{ borderRadius: '20px' }}
                >
                  <h2
                    className="accordion-header"
                    id={`heading-${dettagliPartecipazione.id}`}
                  >
                    <button
                      className="accordion-button accordion-button-stellantis collapsed py-4"
                      style={{
                        borderRadius: '20px',
                        backgroundColor: eventDetails.colorePrimarioEvento,
                      }}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#trasporti-${dettagliPartecipazione.id}`}
                      aria-expanded="false"
                      aria-controls={`trasporti-${dettagliPartecipazione.id}`}
                    >
                      <b>Trasporti</b>
                    </button>
                  </h2>
                  <div
                    id={`trasporti-${dettagliPartecipazione.id}`}
                    className="accordion-collapse collapse"
                    aria-labelledby={`heading-${dettagliPartecipazione.id}`}
                    data-bs-parent="#accordionDatiPartecipazione"
                  >
                    <div className="accordion-body">
                      <div className="mb-3">
                        <label
                          className="form-label text-black mt-2"
                          htmlFor="mezzo"
                        >
                          Mezzo di trasporto
                        </label>
                        <select
                          className="form-select mb-1"
                          aria-label="Mezzo"
                          id="mezzo"
                          value={
                            dettagliPartecipazione.trasporti &&
                            dettagliPartecipazione.trasporti.mezzo
                          }
                          onChange={(e) => handleChange(e, 'trasporti')}
                        >
                          <option value="aereo">Aereo</option>
                          <option value="treno">Treno</option>
                          <option value="macchina">Auto</option>
                        </select>

                        <label
                          className="form-label mt-3"
                          htmlFor="nVoloAndata"
                        >
                          Numero volo di andata
                        </label>
                        <input
                          type="text"
                          className="form-control mb-1"
                          id="nVoloAndata"
                          placeholder="Inserisci numero volo andata"
                          value={
                            dettagliPartecipazione.trasporti &&
                            dettagliPartecipazione.trasporti.nVoloAndata
                          }
                          onChange={(e) => handleChange(e, 'trasporti')}
                        />
                        <label
                          className="form-label mt-3"
                          htmlFor="oraArrivoAndata"
                        >
                          Ora di arrivo andata
                        </label>
                        <input
                          type="time"
                          className="form-control mb-1"
                          id="oraArrivoAndata"
                          placeholder="Inserisci ora di arrivo andata"
                          value={
                            dettagliPartecipazione.trasporti &&
                            dettagliPartecipazione.trasporti.oraArrivoAndata
                          }
                          onChange={(e) => handleChange(e, 'trasporti')}
                        />

                        <label
                          className="form-label mt-3"
                          htmlFor="nVoloAndata"
                        >
                          Numero volo di ritorno
                        </label>
                        <input
                          type="text"
                          className="form-control mb-1"
                          id="nVoloRitorno"
                          placeholder="Inserisci numero volo ritorno"
                          value={
                            dettagliPartecipazione.trasporti &&
                            dettagliPartecipazione.trasporti.nVoloRitorno
                          }
                          onChange={(e) => handleChange(e, 'trasporti')}
                        />

                        <label
                          className="form-label mt-3"
                          htmlFor="oraPartenzaRitorno"
                        >
                          Ora di partenza ritorno
                        </label>
                        <input
                          type="time"
                          className="form-control mb-1"
                          id="oraPartenzaRitorno"
                          placeholder="Inserisci ora di partenza ritorno"
                          value={
                            dettagliPartecipazione.trasporti &&
                            dettagliPartecipazione.trasporti.oraPartenzaRitorno
                          }
                          onChange={(e) => handleChange(e, 'trasporti')}
                        />
                        <button
                          className="btn  w-sm waves-effect waves-light w-100 text-white mt-4 py-3"
                          onClick={AggiornaDatiPartecipazioneUtente}
                          style={{
                            backgroundColor: eventDetails.colorePrimarioEvento,
                          }}
                        >
                          Aggiorna dati
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* Accordion accomodation */}

              {dettagliPartecipazione && (
                <div
                  className="accordion-item mb-3 border-0"
                  style={{ borderRadius: '20px' }}
                >
                  <h2
                    className="accordion-header"
                    id={`heading-${dettagliPartecipazione.id}`}
                  >
                    <button
                      className="accordion-button accordion-button-stellantis collapsed py-4"
                      style={{
                        borderRadius: '20px',
                        backgroundColor: eventDetails.colorePrimarioEvento,
                      }}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#accomodation-${dettagliPartecipazione.id}`}
                      aria-expanded="false"
                      aria-controls={`accomodation-${dettagliPartecipazione.id}`}
                    >
                      <b>Accomodation</b>
                    </button>
                  </h2>
                  <div
                    id={`accomodation-${dettagliPartecipazione.id}`}
                    className="accordion-collapse collapse"
                    aria-labelledby={`heading-${dettagliPartecipazione.id}`}
                    data-bs-parent="#accordionDatiPartecipazione"
                  >
                    <div className="accordion-body">
                      <div className="mb-3">
                        <div className="form-check my-2">
                          <label className="form-check-label" htmlFor="cena">
                            Cena
                          </label>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="cena"
                            checked={
                              dettagliPartecipazione.accomodation
                                ? dettagliPartecipazione.accomodation.cena
                                : false
                            }
                            onChange={(e) => {
                              const { id, checked } = e.target;
                              setDettagliPartecipazione((prevState) => ({
                                ...prevState,
                                accomodation: {
                                  ...prevState.accomodation,
                                  [id]: checked,
                                },
                              }));
                            }}
                          />
                        </div>

                        <div className="form-check mb-2">
                          <label
                            className="form-check-label"
                            htmlFor="pernottamento"
                          >
                            Pernottamento
                          </label>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="pernottamento"
                            checked={
                              dettagliPartecipazione.accomodation
                                ? dettagliPartecipazione.accomodation
                                    .pernottamento
                                : false
                            }
                            onChange={(e) => {
                              const { id, checked } = e.target;
                              setDettagliPartecipazione((prevState) => ({
                                ...prevState,
                                accomodation: {
                                  ...prevState.accomodation,
                                  [id]: checked,
                                },
                              }));
                            }}
                          />
                        </div>

                        <label className="form-label" htmlFor="intolleranze">
                          Intolleranze
                        </label>
                        <textarea
                          type="text"
                          className="form-control mb-1"
                          id="intolleranze"
                          placeholder="Inserisci intolleranze"
                          value={
                            dettagliPartecipazione.accomodation &&
                            dettagliPartecipazione.accomodation.intolleranze
                          }
                          onChange={(e) => handleChange(e, 'accomodation')}
                        ></textarea>

                        <button
                          className="btn w-sm waves-effect waves-light w-100 text-white mt-4 py-3"
                          onClick={AggiornaDatiPartecipazioneUtente}
                          style={{
                            backgroundColor: eventDetails.colorePrimarioEvento,
                          }}
                        >
                          Aggiorna dati
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* Accordion documenti */}

              {dettagliPartecipazione && (
                <div
                  className="accordion-item mb-3 border-0"
                  style={{ borderRadius: '20px' }}
                >
                  <h2
                    className="accordion-header"
                    id={`heading-${dettagliPartecipazione.id}`}
                  >
                    <button
                      className="accordion-button collapsed py-4"
                      style={{
                        borderRadius: '20px',
                        backgroundColor: eventDetails.colorePrimarioEvento,
                      }}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#documenti-evento-${dettagliPartecipazione.id}`}
                      aria-expanded="false"
                      aria-controls={`documenti-evento-${dettagliPartecipazione.id}`}
                    >
                      <b className="text-white">Documenti</b>
                    </button>
                  </h2>
                  <div
                    id={`documenti-evento-${dettagliPartecipazione.id}`}
                    className="accordion-collapse collapse"
                    aria-labelledby={`heading-${dettagliPartecipazione.id}`}
                    data-bs-parent="#accordionDatiPartecipazione"
                  >
                    <div className="accordion-body">
                      {dettagliPartecipazione &&
                      dettagliPartecipazione.lista_documenti_evento &&
                      Array.isArray(
                        dettagliPartecipazione.lista_documenti_evento
                      ) &&
                      dettagliPartecipazione.lista_documenti_evento.length ===
                        0 ? (
                        <p>Trovato nulla</p>
                      ) : dettagliPartecipazione.lista_documenti_evento &&
                        Array.isArray(
                          dettagliPartecipazione.lista_documenti_evento
                        ) ? (
                        dettagliPartecipazione.lista_documenti_evento.map(
                          (documento) => {
                            return (
                              <div className="card my-2 mb-3">
                                <div className="card-body">
                                  <a
                                    href={`${documento.file}`}
                                    className="text-decoration-none d-flex justify-content-between align-items-center"
                                    target="_blank"
                                  >
                                    <span className=" text-dark">
                                      <IconaAttachment /> {documento.tipologia}
                                    </span>
                                    <span>Visualizza</span>
                                  </a>
                                </div>
                              </div>
                            );
                          }
                        )
                      ) : (
                        <p>Non ci sono documenti disponibili</p>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {/* Accordion note generali  */}
              {dettagliPartecipazione && (
                <div
                  className="accordion-item mb-3 border-0"
                  style={{ borderRadius: '20px' }}
                >
                  <h2
                    className="accordion-header"
                    id={`heading-${dettagliPartecipazione.id}`}
                  >
                    <button
                      className="accordion-button accordion-button-stellantis collapsed py-4"
                      style={{
                        borderRadius: '20px',
                        backgroundColor: eventDetails.colorePrimarioEvento,
                      }}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#note-${dettagliPartecipazione.id}`}
                      aria-expanded="false"
                      aria-controls={`note-${dettagliPartecipazione.id}`}
                    >
                      <b>Note</b>
                    </button>
                  </h2>
                  <div
                    id={`note-${dettagliPartecipazione.id}`}
                    className="accordion-collapse collapse"
                    aria-labelledby={`heading-${dettagliPartecipazione.id}`}
                    data-bs-parent="#accordionDatiPartecipazione"
                  >
                    <div className="accordion-body">
                      <label className="form-label" htmlFor="note">
                        Note
                      </label>
                      <textarea
                        type="text"
                        className="form-control mb-1"
                        id="note"
                        placeholder="Inserisci note"
                        value={
                          dettagliPartecipazione.note &&
                          dettagliPartecipazione.note.note
                        }
                        onChange={(e) => handleChange(e, 'note')}
                      ></textarea>
                      <button
                        className="btn w-sm waves-effect waves-light w-100 text-white mt-4 py-3"
                        onClick={AggiornaDatiPartecipazioneUtente}
                        style={{
                          backgroundColor: eventDetails.colorePrimarioEvento,
                        }}
                      >
                        Aggiorna dati
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Accordion Anagrafica Partecipante  */}

          <div className="col-12">
            <div className="my-3 text-end d-flex align-items-center justify-content-between">
              <Link
                to={`/dashboard?eventCode=${eventCode}`}
                className="btn w-sm waves-effect waves-light w-100 text-white py-3"
                type="submit"
                style={{ backgroundColor: eventDetails.colorePrimarioEvento }}
              >
                Indietro
              </Link>
              {/* <a
                // to={"/accomodation"}
                href="mailto:nobody@test.com"
                className="btn bg-white w-sm waves-effect waves-light w-100 me-2 p-0 d-flex justify-content-between"
                type="submit"
                style={{ height: "60px" }}
              >
                <span className="text-black d-flex align-items-center font-size-11 text-start px-2">
                  <b>Contatta il servizio per cambio dati</b>
                </span>
                <span
                  className="bg-blue-stellantis d-flex align-items-center justify-content-center fs-3 text-white"
                  style={{ minWidth: "60px" }}
                >
                  <IconMail />
                </span>
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AnagraficaPartecipanteHostess;
