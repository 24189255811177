import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react';
import axios from 'axios';
import { StateContext } from '../../../components/StateContainer';

function SelezionaSessione({
  toggleTempParam,
  sessionId,
  setSessionId,
  essionName,
  setSessionName,
}) {
  const { eventCode, autologinCode, setLoading, token } =
    useContext(StateContext);

  const [sessioniList, setSessioniList] = useState([]);

  useEffect(() => {
    console.log(eventCode);
    console.log(autologinCode);
    setLoading(true);
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/sessioni/list`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      data: {
        eventCode: eventCode,
        autologinCode: autologinCode,
      },
    })
      .then((response) => {
        setLoading(false);
        setSessioniList(response.data.response);
        // Recupera sessionId dalla URL e imposta il nome corretto
        const urlParams = new URLSearchParams(window.location.search);
        const sessionIdFromUrl = urlParams.get('sessione');

        if (sessionIdFromUrl) {
          const sessionIdNumber = parseInt(sessionIdFromUrl, 10); // Converte in numero
          const sessioneTrovata = response.data.response.find(
            (s) => s.id === sessionIdNumber
          );

          if (sessioneTrovata) {
            setSessionId(sessionIdNumber);
            setSessionName(sessioneTrovata.nome);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, []);

  let buttonChiudiSelezioneSessioni = document.getElementById(
    'chiudiSelezioneSessioni'
  );

  const ClickSessionButton = (name, sessionId, sessionName, e) => {
    setSessionId(sessionId);
    setSessionName(sessionName);
    buttonChiudiSelezioneSessioni.click();
    console.log(name, sessionId, sessionName, e);
    toggleTempParam(name, sessionId, e);
  };

  return (
    <div
      className="modal fade"
      id="modalSelezionaSessione"
      tabIndex="-1"
      aria-labelledby="modalSelezionaSessione"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div
          className="modal-content overflow-hidden"
          style={{
            borderColor: 'transparent',
            borderRadius: '10px 10px 10px 10px',
            overflow: 'hidden',
            boxShadow:
              'rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px',
          }}
        >
          <div className="modal-header bg-dark d-flex justify-content-center">
            <h1
              className="modal-title fs-5 text-white text-center"
              id="modalSelezionaSessione"
            >
              <b>Seleziona sessione </b>
              <br />
            </h1>
          </div>
          <div
            className="modal-body modal-body-custom p-0 m-0"
            style={{ height: 'auto' }}
          >
            {sessioniList.length === 0 ? (
              <p className="my-3 text-center">Nessuna sessione trovata</p>
            ) : (
              sessioniList.map((sessione) => {
                return (
                  <div
                    className="text-center py-3"
                    style={{
                      borderTop: '1x black solid',
                      borderBottom: '1px black solid',
                      cursor: 'pointer',
                    }}
                    onClick={(e) =>
                      ClickSessionButton(
                        'sessione',
                        sessione.id,
                        sessione.nome,
                        e
                      )
                    }
                  >
                    <span>{sessione.nome}</span>
                  </div>
                );
              })
            )}
          </div>
          <div className="d-flex align-items-center overflow-hidden">
            <button
              type="button"
              id="chiudiSelezioneSessioni"
              className="btn text-black bg-grey-stellantis w-100 rounded-0 py-3"
              data-bs-dismiss="modal"
            >
              Annulla
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SelezionaSessione;
