import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react';
import jsQR from 'jsqr';
import { Scanner } from '@yudiel/react-qr-scanner';
import '../../../App.css';
import 'yet-another-react-lightbox/styles.css';
import Lightbox from 'yet-another-react-lightbox';
import { StateContext } from '../../../components/StateContainer';
import { IoDocument as IconaDocumento } from 'react-icons/io5';
import { Link, useParams } from 'react-router-dom';
import Logout from '../logout/Logout';
import Webcam from 'react-webcam';
import axios from 'axios';
import SignatureCanvas from 'react-signature-canvas';
import { FaCamera as Fotocamera } from 'react-icons/fa';
import { IoMdClose as Close } from 'react-icons/io';
// import { IoMdClose as Scanner } from 'react-icons/io';
import { FaImages as IconaImmagine } from 'react-icons/fa6';

import { FaRotate as Rotate } from 'react-icons/fa6';
import jsPDF from 'jspdf';

// npm i @yudiel/react-qr-scanner

function ScannerQrCodePartecipante({
  handleChange,
  accendiFotocamera,
  setAccendiFotocamera,
  codiceValido,
  setCodiceValido,
  firmaInserita,
  setFirmaInserita,
  eventDetails,
}) {
  const sigCanvasRef = useRef({});

  console.log(eventDetails);

  //   const [accendiFotocamera, setAccendiFotocamera] = useState(false);
  const webcamRef = useRef(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [ocrText, setOcrText] = useState('');
  const [loading, setLoading] = useState(false);
  const [consensoUsoImmagine, setConsensoUsoImmagine] = useState(true);
  const [changeCameraAngle, setChangeCameraAngle] = useState(false);
  const { idSessione } = useParams();
  const [dataScadenza, setDataScadenza] = useState('');
  const [temporaryDate, setTemporaryDate] = useState('');
  const [files, setFiles] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);
  const { setPageTitle, eventCode, autologinCode, token } =
    useContext(StateContext);

  useEffect(() => {
    setPageTitle('Caricamento Documenti');
  }, []);

  const [currentIndex, setCurrentIndex] = useState(0); // Traccia l'indice corrente

  const handleSkipDocument = () => {
    if (currentIndex < eventDetails.tipologieDocumenti.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const { idPartecipante } = useParams();

  const [documentiMancanti, setDocumentiMancanti] = useState([]);

  useEffect(() => {
    if (!codiceValido) return;
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/documenti-caricati-partecipante/list`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      data: {
        eventCode: eventCode,
        codicePartecipazione: codiceValido,
      },
    })
      .then((response) => {
        setDocumentiMancanti(response.data.response.documenti_mancanti);
        console.log(response.data.response.anagrafica);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [codiceValido]);

  const formRef = useRef(null); // Riferimento al form

  const handleFileChange = (event) => {
    event.preventDefault();
    const selectedFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  };

  const removeFile = (index) => {
    setFiles(files.filter((_, i) => i !== index));
  };

  const handleConfirm = async (event, tipologiaId) => {
    event.preventDefault();

    // Se il documento non è obbligatorio e non ci sono file, passa direttamente al prossimo documento
    if (files.length === 0 && !documentiMancanti[currentIndex]?.obbligatorio) {
      if (currentIndex < documentiMancanti.length - 1) {
        setCurrentIndex((prevIndex) => prevIndex + 1);
      } else {
        console.log('Tutti i documenti sono stati processati');
        setCurrentIndex(documentiMancanti.length);
      }
      return;
    }

    if (files.length > 0) {
      const pdf = new jsPDF();
      let isFirstPage = true;
      for (const file of files) {
        const dataUrl = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });

        const img = new Image();
        img.src = dataUrl;

        await new Promise((resolve) => {
          img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const targetWidth = 800;
            const targetHeight = 500;
            const scale = Math.min(
              targetWidth / img.width,
              targetHeight / img.height
            );
            const newWidth = Math.round(img.width * scale);
            const newHeight = Math.round(img.height * scale);
            canvas.width = newWidth;
            canvas.height = newHeight;
            ctx.drawImage(img, 0, 0, newWidth, newHeight);
            const compressedImage = canvas.toDataURL(file.type || 'image/jpeg');
            if (!isFirstPage) pdf.addPage();
            isFirstPage = false;
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            const scalePdf = Math.min(
              pdfWidth / newWidth,
              pdfHeight / newHeight
            );
            const pdfImgWidth = newWidth * scalePdf;
            const pdfImgHeight = newHeight * scalePdf;
            pdf.addImage(
              compressedImage,
              'JPEG',
              (pdfWidth - pdfImgWidth) / 2,
              (pdfHeight - pdfImgHeight) / 2,
              pdfImgWidth,
              pdfImgHeight
            );
            resolve();
          };
        });
      }
      const pdfBlob = pdf.output('blob');
      const pdfFile = new File([pdfBlob], 'immagini_combine.pdf', {
        type: 'application/pdf',
      });

      startUploadDocumentiEvento([pdfFile], tipologiaId);
    }

    // Passa al documento successivo
    if (currentIndex < documentiMancanti.length - 1) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    } else {
      console.log('Tutti i documenti sono stati processati');
      setCurrentIndex(documentiMancanti.length);
    }
    setFiles([]);
    if (formRef.current) formRef.current.reset();
  };

  // Funzione per salvare il file nella cartella temporanea
  const saveFileToTempFolder = (blob, filePath) => {
    return new Promise((resolve, reject) => {
      const tempFolder = './documento-cliente'; // Nome della cartella temporanea
      const fullPath = `${tempFolder}/${filePath}`;

      // Usa l'API di file system o una libreria per scrivere il file
      const fileSaver = require('file-saver');
      try {
        fileSaver.saveAs(blob, fullPath);
        resolve(fullPath); // Restituisce il percorso del file salvato
      } catch (error) {
        reject(error);
      }
    });
  };

  const GetCodeQrCode = (result) => {
    setLoading(true);
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/partecipazione/check-qrcode`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      data: {
        eventCode: eventCode,
        codeQRCode: result[0].rawValue,
      },
    })
      .then((response) => {
        setLoading(false);
        setCodiceValido(response.data.response.codiceValido);
        // window.location.replace(
        //   `/sessioni/${idSessione}/accomodation?eventCode=${eventCode}&autologinCode=${autologinCode}`
        // );
      })
      .catch((error) => {
        if (error.response.status == '401') {
          window.location.replace(
            `/login?eventCode=${eventCode}&autologinCode=${autologinCode}`
          );
        }
      });
  };

  const AnnullaProcesso = () => {
    setImageSrc('');
    setOcrText('');
    setDataScadenza('');
    setAccendiFotocamera(false);
    setCodiceValido('');
    setTemporaryDate('');
  };

  const [contentData, setContentData] = useState([]);
  const [uploadProgress, setUploadProgress] = useState([]);
  const [currentRequestId, setCurrentRequestId] = useState(null);

  // const uploadFirmaCliente = () => {
  //   console.log(sigCanvasRef);
  //   const dataUrl = sigCanvasRef.current.toDataURL("image/png");

  //   const imageName = "signature.png"; // Nome dell'immagine

  //   console.log(dataUrl);
  //   console.log(sigCanvasRef);
  //   const cancelFile = axios.CancelToken.source();
  //   setUploadProgress((prevProgress) => [
  //     ...prevProgress,
  //     {
  //       file: dataUrl,
  //       progress: 0,
  //       status: "uploading",
  //       cancelToken: cancelFile,
  //     },
  //   ]);
  //   setContentData((currentScene) => [...currentScene, dataUrl]);

  //   const timestamp = new Date().toISOString().replace(/[:.-]/g, "");
  //   const requestId = `upload_${timestamp}_${imageName}`;
  //   setCurrentRequestId(requestId);
  //   const config = {
  //     method: "POST",
  //     url: `${process.env.REACT_APP_BASE_URL}/upload-firma-cliente`,
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + token,
  //     },
  //     data: {
  //       keyName: requestId,
  //       signature: dataUrl,
  //       codePartecipazione: codiceValido,
  //     },
  //   };

  //   axios(config).then((res) => {
  //     console.log(res);
  //   });
  // };

  const uploadFirmaCliente = () => {
    const dataUrl = sigCanvasRef.current.toDataURL('image/png');

    if (dataUrl) {
      const targetWidth = 800;
      const targetHeight = 500;

      // Creare un elemento immagine per caricare il file dal dataUrl
      const img = new Image();
      img.src = dataUrl;

      img.onload = function () {
        const width = img.width;
        const height = img.height;

        // Crea un canvas con le dimensioni target
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = targetWidth;
        canvas.height = targetHeight;

        // Calcola il rapporto di ridimensionamento
        const scaleX = targetWidth / width;
        const scaleY = targetHeight / height;

        // Disegna l'immagine ridimensionata sul canvas con le dimensioni target
        ctx.drawImage(
          img,
          0,
          0,
          width,
          height,
          0,
          0,
          targetWidth,
          targetHeight
        );

        // Converti il canvas in un Blob
        canvas.toBlob((blob) => {
          if (blob) {
            // Il file è stato ridotto con successo
            const compressedFile = new File([blob], 'firma_compressa.png', {
              type: 'image/png',
            });
            startUpload([compressedFile]);
          }
        }, 'image/png');
      };
    } else {
      return;
    }
  };

  const startUploadDocumentiEvento = (file, tipologiaId) => {
    console.log('sono file', file);
    const fileName = 'documento_evento'; // Nome del file PDF

    const cancelFile = axios.CancelToken.source();
    setUploadProgress((prevProgress) => [
      ...prevProgress,
      {
        file: file[0],
        progress: 0,
        status: 'uploading',
        cancelToken: cancelFile,
      },
    ]);
    setContentData((currentScene) => [...currentScene, file]);
    console.log(file[0]);

    const timestamp = new Date().toISOString().replace(/[:.-]/g, '');
    const requestId = `upload_${fileName}_${timestamp}.pdf`;
    setCurrentRequestId(requestId);

    const config = {
      method: 'POST',
      url: `${process.env.REACT_APP_BASE_URL}/attachment-presigned-url`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      data: {
        keyName: requestId,
      },
    };

    axios(config)
      .then((res) => {
        delete axios.defaults.headers.common['Authorization'];
        const configSecondPart = {
          method: 'PUT',
          url: res.data.response.presignedUrl, // La URL pre-firmata
          headers: {
            'Content-Type': 'application/pdf', // Tipo corretto del file
          },
          data: file[0], // Il file PDF generato
          cancelToken: cancelFile.token,
          onUploadProgress: (progressEvent) => {
            let percentage = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            console.log('Progresso upload:', percentage, '%');
          },
        };

        axios(configSecondPart)
          .then((response) => {
            console.log('File caricato correttamente su S3:', response);
            delete axios.defaults.headers.common['Authorization'];
            const configThirdPart = {
              method: 'POST',
              url: `${process.env.REACT_APP_BASE_URL}/upload-documenti-evento`,
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
              },
              data: {
                keyName: requestId,
                codePartecipazione: codiceValido,
                tipologiaId: tipologiaId,
              },
            };

            axios(configThirdPart)
              .then(() => {
                // setCurrentIndex((prev) => prev + 1);
                // console.log(
                //   currentIndex,
                //   eventDetails.tipologieDocumenti.length
                // );
                // setImageSrc('');
                // setOcrText('');
                // setDataScadenza('');
                // setAccendiFotocamera(false);
                // setCodiceValido('');
                // setTemporaryDate('');
                // setFirmaInserita(true);
              })
              .catch((e) => {
                console.error('Errore nella terza parte:', e);
              });
          })
          .catch((err) => {
            if (axios.isCancel(err)) {
              console.log('Upload annullato');
            } else {
              console.error("Errore durante l'upload del file:", err);
            }
          });
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log(`Request ${requestId} canceled`);
        } else {
          console.error('Errore nella prima parte della richiesta:', err);
        }
      });
  };

  const startUpload = (dataUrl) => {
    const imageName = 'signature'; // Nome dell'immagine

    const cancelFile = axios.CancelToken.source();
    setUploadProgress((prevProgress) => [
      ...prevProgress,
      {
        file: dataUrl[0],
        progress: 0,
        status: 'uploading',
        cancelToken: cancelFile,
      },
    ]);
    setContentData((currentScene) => [...currentScene, dataUrl[0]]);

    const timestamp = new Date().toISOString().replace(/[:.-]/g, '');
    const requestId = `upload_${imageName}_${timestamp}.png`;
    setCurrentRequestId(requestId);

    const config = {
      method: 'POST',
      url: `${process.env.REACT_APP_BASE_URL}/attachment-presigned-url`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      data: {
        keyName: requestId,
      },
    };

    axios(config)
      .then((res) => {
        delete axios.defaults.headers.common['Authorization'];
        const configSecondPart = {
          method: 'put',
          url: res.data.response.presignedUrl,
          headers: {
            Accept: `image/png`,
            'Content-Type': `image/png`,
          },
          data: dataUrl[0],
          cancelToken: cancelFile.token,
          onUploadProgress: (progressEvent) => {
            let percentage = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        };
        axios(configSecondPart)
          .then((res) => {
            console.log(res);
            delete axios.defaults.headers.common['Authorization'];
            const configThirdPart = {
              method: 'POST',
              // url: `${process.env.REACT_APP_BASE_URL}/upload-chat-icon`,
              url: `${process.env.REACT_APP_BASE_URL}/upload-firma-cliente`,
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
              },
              data: {
                keyName: requestId,
                codePartecipazione: codiceValido,
                consensoUsoImmagine: consensoUsoImmagine,
              },
            };

            axios(configThirdPart)
              .then((res) => {
                setImageSrc('');
                setOcrText('');
                setCurrentIndex(0);
                setDataScadenza('');
                setAccendiFotocamera(false);
                setCodiceValido('');
                setTemporaryDate('');
                setFirmaInserita(true);
              })
              .catch((e) => {
                console.log(e);
              });
          })
          .catch((err) => {
            if (axios.isCancel(err)) {
            } else {
              console.log('AXIOS ERROR 2: ', err);
            }
          });
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log(`Request ${requestId} canceled`);
        } else {
          console.log('AXIOS ERROR 1: ', err);
        }
      });
  };

  // const j = (file) => {
  //   const cancelFile = axios.CancelToken.source();
  //   setUploadProgress((prevProgress) => [
  //     ...prevProgress,
  //     {
  //       file: file[0],
  //       progress: 0,
  //       status: "uploading",
  //       cancelToken: cancelFile,
  //     },
  //   ]);
  //   setContentData((currentScene) => [...currentScene, file[0]]);

  //   const requestId = `upload_${file[0].name}`;
  //   setCurrentRequestId(requestId);

  //   const config = {
  //     method: "POST",
  //     url: `${process.env.REACT_APP_BASE_URL}/attachment-presigned-url`,
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + token,
  //     },
  //     data: {
  //       keyName: `${file[0].name}`,
  //     },
  //   };

  //   axios(config)
  //     .then((res) => {
  //       delete axios.defaults.headers.common["Authorization"];
  //       const configSecondPart = {
  //         method: "put",
  //         url: res.data.response.presignedUrl,
  //         headers: {
  //           Accept: `${file[0].type}`,
  //           "Content-Type": `${file[0].type}`,
  //         },
  //         data: file[0],
  //         cancelToken: cancelFile.token,
  //         onUploadProgress: (progressEvent) => {
  //           let percentage = Math.round(
  //             (progressEvent.loaded * 100) / progressEvent.total
  //           );
  //         },
  //       };
  //       axios(configSecondPart)
  //         .then((res) => {
  //           delete axios.defaults.headers.common["Authorization"];
  //           const configThirdPart = {
  //             method: "POST",
  //             url: `${process.env.REACT_APP_BASE_URL}/upload-chat-icon`,
  //             headers: {
  //               Accept: "application/json",
  //               "Content-Type": "application/json",
  //               Authorization: "Bearer " + token,
  //             },
  //             data: {
  //               keyName: file[0].name,
  //               projectCode: id,
  //             },
  //           };

  //           axios(configThirdPart)
  //             .then((res) => {})
  //             .catch((e) => {
  //               console.log(e);
  //             });
  //         })
  //         .catch((err) => {
  //           if (axios.isCancel(err)) {
  //           } else {
  //             console.log("AXIOS ERROR 2: ", err);
  //           }
  //         });
  //     })
  //     .catch((err) => {
  //       if (axios.isCancel(err)) {
  //         console.log(`Request ${requestId} canceled`);
  //       } else {
  //         console.log("AXIOS ERROR 1: ", err);
  //       }
  //     });
  // };

  const previewStyle = {
    height: 240,
    width: 320,
    maxHeight: 320,
  };

  const [open, setOpen] = useState(false);

  const images = files.map((file) => ({
    src: URL.createObjectURL(file),
  }));

  const [qrCode, setQrCode] = useState(null); // Stato per memorizzare il QR code

  return (
    <div
      className="offcanvas offcanvas-start w-100 p-0"
      tabIndex="-1"
      id="scannerQrCodePartecipante"
      aria-labelledby="scannerQrCodePartecipante"
      style={{ overflowY: 'scroll' }}
    >
      <div
        className="account-pages bg-grey-stellantis"
        // style={{
        //   height: "100%",
        // }}
      >
        <div className="container">
          <div
            className="row p-0"
            style={{
              height: '100vh',
            }}
          >
            <div
              className="col-12 p-0"
              style={{ height: '100px', marginBottom: '60px' }}
            >
              <div
                className="box-settings-sessioni position-relative"
                style={{ backgroundColor: eventDetails.coloreSecondarioEvento }}
              >
                <Logout />
                <img src={`${eventDetails.logoEvento}`} alt="" />
                <div className="container-nome-evento-sessioni">
                  <div
                    className="box-nome-evento-anagrafica-completa"
                    style={{
                      backgroundColor: eventDetails.colorePrimarioEvento,
                    }}
                  >
                    {eventDetails.nomeEvento}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12" style={{ marginBottom: '30px' }}>
              {codiceValido === '' ? (
                <>
                  <h5
                    className="text-center"
                    style={{ fontSize: '25px', fontWeight: 'bold' }}
                  >
                    Scansione QR CODE
                  </h5>
                  <p className="text-muted text-center">
                    Posizionare il QR Code all'interno del riquadro segnato
                  </p>
                </>
              ) : currentIndex < documentiMancanti.length ? (
                <>
                  <h5
                    className="text-center"
                    style={{ fontSize: '25px', fontWeight: 'bold' }}
                  >
                    Carica i documenti
                  </h5>
                  <p className="text-muted text-center">
                    Selezionare i documenti richiesti per la partecipazione
                    all’evento
                  </p>
                </>
              ) : (
                <>
                  <h5
                    className="text-center"
                    style={{ fontSize: '25px', fontWeight: 'bold' }}
                  >
                    Inserire una firma
                  </h5>
                  <p className="text-muted text-center">
                    Tracciare una firma nello spazio sottostante per confermare
                    la partecipazione all'evento
                  </p>
                </>
              )}
            </div>

            {accendiFotocamera ? (
              codiceValido === '' ? (
                <>
                  <div className="col-12">
                    <div>
                      <div className={'scanner mx-auto'}>
                        <Scanner
                          onScan={(result) => {
                            console.log('onScan triggered');
                            console.log('Scanned result:', result);
                            GetCodeQrCode(result);
                          }}
                          tracker={false}
                        />

                        {/* <Scanner onScan={(result) => console.log(result)} />; */}
                        {qrCode && (
                          <div>
                            <h2>QR Code letto:</h2>
                            <p>{qrCode}</p>{' '}
                            {/* Mostra il risultato del QR code */}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mt-5">
                    <div className="my-3 text-end d-flex align-items-center justify-content-between">
                      <button
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                        className="btn w-sm waves-effect waves-light w-100 text-white me-2 py-3"
                        type="submit"
                        style={{
                          backgroundColor: eventDetails.colorePrimarioEvento,
                        }}
                        onClick={AnnullaProcesso}
                      >
                        Annulla
                      </button>
                      {accendiFotocamera && codiceValido ? (
                        <button
                          // to={"/mezzi-trasporto"}
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                          className="btn w-sm waves-effect waves-light w-100 text-white me-2 py-3"
                          type="submit"
                          style={{
                            backgroundColor: eventDetails.colorePrimarioEvento,
                          }}
                          // disabled={ocrText === "" ? true : false}
                          // onClick={AggiornaDatiDocumenti}
                          onClick={uploadFirmaCliente}
                        >
                          Conferma
                        </button>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </>
              ) : currentIndex < documentiMancanti.length ? (
                <>
                  <div className="col-12">
                    <div>
                      <form ref={formRef}>
                        <h2 className="fw-bold text-center">
                          {documentiMancanti[currentIndex]?.titolo}
                        </h2>
                        <div className="d-flex align-items-center justify-content-center">
                          <label
                            htmlFor={`upload-documento-${currentIndex}`}
                            className="upload-documents-event-label"
                          >
                            {files.length === 0 ? (
                              <span>
                                <IconaDocumento />
                                INIZIA IL CARICAMENTO
                              </span>
                            ) : (
                              <span>
                                <IconaImmagine />
                                AGGIUNGI PAGINE
                              </span>
                            )}

                            <input
                              id={`upload-documento-${currentIndex}`}
                              type="file"
                              accept="image/*"
                              multiple
                              onChange={handleFileChange}
                              style={{ display: 'none' }}
                            />
                          </label>
                        </div>

                        <div className="gallery-container mt-3">
                          {files.map((file, index) => {
                            const url = images[index].src;

                            return (
                              <div
                                key={index}
                                className="image-preview position-relative"
                                style={{ width: '100%', aspectRatio: '1/1' }}
                              >
                                <img
                                  src={url}
                                  alt="preview"
                                  className="img-thumbnail"
                                  style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    setCurrentIndex(index);
                                    setOpen(true);
                                  }}
                                />
                                <button
                                  className="btn btn-danger btn-sm bottone-cancella-foto"
                                  onClick={() => removeFile(index)}
                                >
                                  ✖
                                </button>
                              </div>
                            );
                          })}

                          {/* Lightbox */}
                          <Lightbox
                            open={open}
                            close={() => setOpen(false)}
                            slides={images}
                            index={currentIndex}
                          />
                        </div>

                        {/* {previewImage && (
                          <div className="modal" style={{ display: 'block' }}>
                            <div className="modal-content">
                              <span
                                className="close"
                                onClick={() => setPreviewImage(null)}
                              >
                                &times;
                              </span>
                              <img
                                src={previewImage}
                                alt="Anteprima"
                                style={{ width: '100%' }}
                              />
                            </div>
                          </div>
                        )} */}
                      </form>
                    </div>
                  </div>
                  <div className="col-12 mt-5">
                    <div className="my-3 text-end d-flex align-items-center justify-content-between">
                      <button
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                        className="btn w-sm waves-effect waves-light w-100 text-white me-2 py-3"
                        type="submit"
                        style={{
                          backgroundColor: eventDetails.colorePrimarioEvento,
                        }}
                        onClick={AnnullaProcesso}
                      >
                        Annulla
                      </button>
                      {accendiFotocamera && codiceValido ? (
                        <button
                          className="btn w-sm waves-effect waves-light w-100 text-white me-2 py-3"
                          type="submit"
                          style={{
                            backgroundColor: eventDetails.colorePrimarioEvento,
                          }}
                          onClick={(e) =>
                            handleConfirm(
                              e,
                              documentiMancanti[currentIndex]?.id
                            )
                          }
                          disabled={
                            documentiMancanti[currentIndex]?.obbligatorio &&
                            files.length === 0
                          }
                        >
                          Conferma
                        </button>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-12">
                    <div>
                      <SignatureCanvas
                        ref={sigCanvasRef}
                        penColor="black"
                        canvasProps={{
                          height: '500px',
                          className: 'sigCanvas',
                        }}
                      />
                      <div className="form-check mt-3">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckChecked"
                          checked={consensoUsoImmagine}
                          onChange={(e) =>
                            setConsensoUsoImmagine(!consensoUsoImmagine)
                          }
                        />
                        <label
                          className="form-check-label"
                          for="flexCheckChecked"
                        >
                          Autorizzo la realizzazione e la pubblicazione di
                          foto/video a me durante l'evento
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mt-5">
                    <div className="my-3 text-end d-flex align-items-center justify-content-between">
                      <button
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                        className="btn w-sm waves-effect waves-light w-100 text-white me-2 py-3"
                        type="submit"
                        style={{
                          backgroundColor: eventDetails.colorePrimarioEvento,
                        }}
                        onClick={AnnullaProcesso}
                      >
                        Annulla
                      </button>
                      {accendiFotocamera && codiceValido ? (
                        <button
                          // to={"/mezzi-trasporto"}
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                          className="btn w-sm waves-effect waves-light w-100 text-white me-2 py-3"
                          type="submit"
                          style={{
                            backgroundColor: eventDetails.colorePrimarioEvento,
                          }}
                          // disabled={ocrText === "" ? true : false}
                          // onClick={AggiornaDatiDocumenti}
                          onClick={uploadFirmaCliente}
                        >
                          Conferma
                        </button>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </>
              )
            ) : (
              <div className="col-12">
                <div>
                  <div
                    className="bg-dark"
                    style={{
                      height: '500px',
                      width: '100%',
                      maxHeight: '500px',
                    }}
                  ></div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ScannerQrCodePartecipante;
