import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react';
import axios from 'axios';
import { StateContext } from '../../../components/StateContainer';

function SelezionaGruppo({
  toggleTempParam,
  gruppoId,
  setGruppoId,
  tempParams,
  params,
  gruppoSelected,
  setGruppoSelected,
}) {
  const {
    eventCode,
    autologinCode,
    setLoading,
    token,
    gruppoList,
    setGruppoList,
  } = useContext(StateContext);

  useEffect(() => {
    console.log(eventCode);
    console.log(autologinCode);
    setLoading(true);

    axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/gruppi/list`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      data: {
        eventCode: eventCode,
        autologinCode: autologinCode,
      },
    })
      .then((response) => {
        console.log(response.data.response);
        setLoading(false);
        setGruppoList(response.data.response);

        const urlParams = new URLSearchParams(window.location.search);
        const gruppoIdFromUrl = urlParams.get('gruppo');

        if (gruppoIdFromUrl) {
          const gruppoIdNumber = parseInt(gruppoIdFromUrl, 10);
          const gruppoTrovata = response.data.response.find(
            (s) => s.id === gruppoIdNumber
          );

          if (gruppoTrovata) {
            setGruppoId(gruppoIdNumber);
            setGruppoSelected({
              text: gruppoTrovata.nome,
              colore: gruppoTrovata.colore,
            });
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, []); // Aggiunge "pageLoad" come trigger iniziale

  let buttonChiudiGruppoSessioni = document.getElementById(
    'chiudiGruppoSessioni'
  );

  const ClickGruppoButton = (name, gruppoId, gruppoName, gruppoColore, e) => {
    setGruppoId(gruppoId);
    setGruppoSelected({
      text: gruppoName,
      colore: gruppoColore,
    });
    buttonChiudiGruppoSessioni.click();
    toggleTempParam(name, gruppoId, e);
  };

  return (
    <div
      className="modal fade"
      id="modalSelezionaGruppo"
      tabIndex="-1"
      aria-labelledby="modalSelezionaGruppo"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div
          className="modal-content overflow-hidden"
          style={{
            borderColor: 'transparent',
            borderRadius: '10px 10px 10px 10px',
            overflow: 'hidden',
            boxShadow:
              'rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px',
          }}
        >
          <div className="modal-header bg-dark justify-content-center">
            <h1
              className="modal-title fs-5 text-white text-center "
              id="modalSelezionaGruppo"
            >
              <b>Seleziona gruppo </b>
              <br />
            </h1>
          </div>
          <div
            className="modal-body modal-body-custom p-0 m-0 text-start"
            style={{ height: 'auto' }}
          >
            {gruppoList.length === 0 ? (
              <p className="my-3 text-center">Nessun gruppo trovato</p>
            ) : (
              gruppoList.map((gruppo) => {
                return (
                  <div
                    className="py-3"
                    style={{
                      borderTop: '1x black solid',
                      borderBottom: '1px black solid',
                      cursor: 'pointer',
                    }}
                    onClick={(e) =>
                      ClickGruppoButton(
                        'gruppo',
                        gruppo.id,
                        gruppo.nome,
                        gruppo.colore,
                        e
                      )
                    }
                  >
                    <div className="d-flex align-items-center justify-content-start gap-2">
                      <div
                        className="group-color-select"
                        style={{
                          backgroundColor: `${gruppo.colore}`,
                          marginLeft: '25%',
                        }}
                      ></div>
                      <div>{gruppo.nome}</div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
          <div className="d-flex align-items-center overflow-hidden">
            <button
              type="button"
              id="chiudiGruppoSessioni"
              className="btn text-black bg-grey-stellantis w-100 rounded-0 py-3"
              data-bs-dismiss="modal"
            >
              Annulla
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SelezionaGruppo;
