import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react';
import axios from 'axios';
import { StateContext } from '../../../components/StateContainer';

function SelettoreGruppoPartecipanti({ partecipante }) {
  const {
    eventCode,
    autologinCode,
    setLoading,
    token,
    gruppoList,
    setGruppoList,
    setGroupUpdated,
  } = useContext(StateContext);

  const [selectedGroup, setSelectedGroup] = useState({
    id: partecipante.gruppo.id,
    colore: partecipante.gruppo.colore,
  });

  useEffect(() => {
    console.log('test');
  }, []);

  let buttonChiudiGruppoSessioni = document.getElementById(
    'chiudiGruppoSessioni'
  );

  //   const ClickGruppoButton = (name, gruppoId, gruppoName, gruppoColore, e) => {
  //     setGruppoId(gruppoId);
  //     setGruppoSelected({
  //       text: gruppoName,
  //       colore: gruppoColore,
  //     });
  //     buttonChiudiGruppoSessioni.click();
  //     toggleTempParam(name, gruppoId, e);
  //   };

  const AggiornaGruppoPartecipante = () => {
    setGroupUpdated(false);
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/gruppo-partecipante/update`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      data: {
        eventCode: eventCode,
        idPartecipante: partecipante.id,
        gruppo: selectedGroup.id,
      },
    })
      .then((response) => {
        setGroupUpdated(true);
        const chiudiGruppoSessioniPartecipanti = document.getElementById(
          'chiudiGruppoSessioniPartecipanti'
        );
        if (chiudiGruppoSessioniPartecipanti) {
          chiudiGruppoSessioniPartecipanti.click();
        }
      })
      .catch((error) => {
        if (error.response.status == '401') {
          window.location.replace(
            `/login?eventCode=${eventCode}&autologinCode=${autologinCode}`
          );
        }
      });
  };

  return (
    <div
      className="modal fade"
      id={`modalSelettoreGruppoPartecipanti-${partecipante.id}`}
      aria-labelledby={`modalSelettoreGruppoPartecipanti-${partecipante.id}`}
      tabIndex="-1"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div
          className="modal-content overflow-hidden"
          style={{
            borderColor: 'transparent',
            borderRadius: '10px 10px 10px 10px',
            overflow: 'hidden',
            boxShadow:
              'rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px',
          }}
        >
          <div className="modal-header bg-dark justify-content-center">
            <h1
              className="modal-title fs-5 text-white text-center "
              id="modalSelettoreGruppoPartecipanti"
            >
              <b>Seleziona gruppo </b>
              <br />
            </h1>
          </div>
          <div
            className="modal-body modal-body-custom p-0 m-0 text-start"
            style={{ height: 'auto' }}
          >
            {gruppoList.length === 0 ? (
              <p className="my-3 text-center">Nessun gruppo trovato</p>
            ) : (
              gruppoList.map((gruppo) => {
                return (
                  <div
                    className={`py-3 ${
                      gruppo.id === selectedGroup.id ? 'bg-selected' : ''
                    }`}
                    style={{
                      borderTop: '1x black solid',
                      borderBottom: '1px black solid',
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      setSelectedGroup((prev) => ({
                        ...prev,
                        id: gruppo.id,
                        colore: gruppo.colore,
                      }))
                    }
                  >
                    <div className="d-flex align-items-center justify-content-start gap-2 text-dark">
                      <div
                        className="group-color-select"
                        style={{
                          backgroundColor: `${gruppo.colore}`,
                          marginLeft: '25%',
                        }}
                      ></div>
                      <div>{gruppo.nome}</div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
          <div className="d-flex align-items-center overflow-hidden">
            <button
              type="button"
              id="chiudiGruppoSessioniPartecipanti"
              className="btn text-black bg-grey-stellantis w-100 rounded-0 py-3"
              data-bs-dismiss="modal"
            >
              Annulla
            </button>
            <button
              type="button"
              id="confermaGruppoSessioniPartecipanti"
              className="btn text-white bg-success w-100 rounded-0 py-3"
              data-bs-dismiss="modal"
              onClick={AggiornaGruppoPartecipante}
            >
              Conferma
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SelettoreGruppoPartecipanti;
