import React, { useContext, useEffect, useState } from 'react';
import '../App.css';
import axios from 'axios';
import useAuth from '../UseAuth';
import { StateContext } from '../components/StateContainer';
import { Link, useParams } from 'react-router-dom';
import Logout from './components/logout/Logout';
function AnagraficaCliente() {
  const { idSessione } = useParams();
  const {
    loading,
    setLoading,
    error,
    setError,
    setPageTitle,
    token,
    autologinCode,
    eventCode,
    dettagliEvento,
  } = useContext(StateContext);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login } = useAuth();

  useEffect(() => {
    setPageTitle('Anagrafica cliente');
  }, []);

  // Inizializza lo stato con un array vuoto
  const [dettagliAnagrafica, setDettagliAnagrafica] = useState([]);

  // Gestore di eventi per l'aggiornamento dei valori degli input
  const handleChange = (key, value) => {
    // Aggiorna lo stato con il nuovo valore per la chiave specificata
    setDettagliAnagrafica((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };

  const [dettagliPartecipazione, setDettagliPartecipazione] = useState({});

  useEffect(() => {
    setError('');
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/partecipazione/detail`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      data: {
        eventCode: eventCode,
      },
    })
      .then((response) => {
        console.log(response);
        if (response.data && response.data.error) {
          setError(response.data.error);
        } else {
          console.log(response);

          setDettagliPartecipazione(response.data.response);
        }
      })
      .catch((error) => {
        if (error.response.status == '401') {
          window.location.replace(
            `/login?eventCode=${eventCode}&autologinCode=${autologinCode}`
          );
        }
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/user/detail`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      data: {
        eventCode: eventCode,
        codiceAutologin: autologinCode,
      },
    })
      .then((response) => {
        setLoading(false);
        setDettagliAnagrafica(response.data.response.user);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == '401') {
          window.location.replace(
            `/login?eventCode=${eventCode}&autologinCode=${autologinCode}`
          );
        }
      });
  }, []);

  const AggiornaDatiCliente = () => {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/user/update`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      data: {
        eventCode: eventCode,
        codiceAutologin: autologinCode,
        datiAnagrafica: dettagliAnagrafica,
      },
    })
      .then((response) => {
        console.log(response);
        window.location.replace(
          `/sessioni/${idSessione}/caricamento-documenti?eventCode=${eventCode}&autologinCode=${autologinCode}`
        );
      })
      .catch((error) => {
        if (error.response.status == '401') {
          window.location.replace(
            `/login?eventCode=${eventCode}&autologinCode=${autologinCode}`
          );
        }
      });
  };

  const datiAnagrafica = [
    {
      id: 1,
      label: 'Cognome',
      input: 'lastname',
      placeholder: 'Inserisci Cognome',
      type: 'text',
      value: dettagliAnagrafica.lastname,
      disabled: false,
    },
    {
      id: 2,
      label: 'Nome',
      input: 'firstname',
      placeholder: 'Inserisci Nome',
      type: 'text',
      value: dettagliAnagrafica.firstname,
      disabled: false,
    },
    // {
    //   id: 3,
    //   label: "Email",
    //   input: "email",
    //   placeholder: "Inserisci Email",
    //   type: "email",
    //   value: dettagliAnagrafica.email,
    //   disabled: true,
    // },
    {
      id: 5,
      label: 'Città',
      input: 'city',
      placeholder: 'Inserisci la città',
      type: 'text',
      value: dettagliAnagrafica.city,
      disabled: false,
    },
    {
      id: 6,
      label: 'Provincia',
      input: 'provincia',
      placeholder: 'Inserisci la provincia',
      type: 'text',
      value: dettagliAnagrafica.provincia,
      disabled: false,
    },
    {
      id: 7,
      label: 'Ragione sociale',
      input: 'ragioneSociale',
      placeholder: 'Inserisci la ragione sociale',
      type: 'text',
      value: dettagliAnagrafica.ragioneSociale,
      disabled: false,
    },
    {
      id: 7,
      label: 'Numero di telefono',
      input: 'numeroTelefono',
      placeholder: 'Inserisci il tuo numero di telefono',
      type: 'text',
      value: dettagliAnagrafica.numeroTelefono,
      disabled: false,
    },
    // {
    //   id: 9,
    //   label: "Codice concessionaria",
    //   input: "codiceConcessionaria",
    //   placeholder: "Inserisci il codice concessionaria",
    //   type: "text",
    //   value: dettagliAnagrafica.codiceConcessionaria,
    //   disabled: false,
    // },
  ];

  const sessioneId = dettagliPartecipazione.sessioneId;
  console.log(sessioneId);

  return (
    <div
      className="account-pages bg-grey-stellantis"
      style={{
        height: 'auto',
        minHeight: '100vh',
      }}
    >
      <div className="container">
        <div
          className="row"
          style={{
            // minHeight: "100vh",
            height: 'auto',
          }}
        >
          <div
            className="col-12 p-0"
            style={{ height: '100px', marginBottom: '60px' }}
          >
            <div
              className="box-settings-sessioni position-relative"
              style={{ backgroundColor: dettagliEvento.coloreSecondarioEvento }}
            >
              <Logout />
              <img src={`${dettagliEvento.logoEvento}`} alt="" />
              <div className="container-nome-evento-sessioni">
                <div
                  className="box-nome-evento-sessioni"
                  style={{
                    backgroundColor: dettagliEvento.colorePrimarioEvento,
                  }}
                >
                  {dettagliEvento.nomeEvento}
                </div>
                <div className="box-caricamento p-0 bg-white">
                  <div
                    className="progress-bar progress-bar-selezione progress-bar-anagrafica"
                    role="progressbar"
                    style={{
                      width: '33.4%',
                      backgroundColor: dettagliEvento.colorePrimarioEvento,
                      opacity: 0.4,
                    }}
                    aria-valuenow="33.4"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12" style={{ marginBottom: '30px' }}>
            <h5
              className="text-center"
              style={{ fontSize: '25px', fontWeight: 'bold' }}
            >
              Anagrafica
            </h5>
            <p className="text-muted text-center">
              In questa sezione può verificare i dati inseriti<br></br>ed
              eventualmente modificarli ove possibile.
            </p>
          </div>
          <div className="col-12">
            {loading ? (
              <div className="overlay-loading d-flex justify-content-center align-items-center">
                <div
                  className="spinner-border spinner-loading text-primary fs-1"
                  role="status"
                ></div>
              </div>
            ) : (
              datiAnagrafica.map((dato, index) => {
                return (
                  <div className="form-group mb-3" key={dato.id}>
                    <label
                      htmlFor={dato.input}
                      className="text-muted"
                      style={{ fontSize: '14px' }}
                    >
                      {dato.label}
                    </label>
                    <input
                      type={dato.type}
                      className="form-control mt-1 border-0"
                      disabled={dato.disabled}
                      id={dato.input}
                      placeholder={dato.placeholder}
                      value={dato.value || ''}
                      style={{ borderRadius: '6px' }}
                      onChange={(e) => handleChange(dato.input, e.target.value)}
                    />
                  </div>
                );
              })
            )}
          </div>
          <div className="col-12">
            <div className="my-3 mb-5 text-end d-flex align-items-center justify-content-between">
              <Link
                to={
                  sessioneId == null
                    ? `/sessioni?eventCode=${eventCode}&autologinCode=${autologinCode}`
                    : `/partecipo?eventCode=${eventCode}&autologinCode=${autologinCode}`
                }
                className="btn w-sm waves-effect waves-light w-100 text-white me-2 py-3"
                type="submit"
                style={{ backgroundColor: dettagliEvento.colorePrimarioEvento }}
              >
                Indietro
              </Link>
              <button
                className="btn w-sm waves-effect waves-light w-100 text-white py-3"
                type="submit"
                onClick={AggiornaDatiCliente}
                style={{ backgroundColor: dettagliEvento.colorePrimarioEvento }}
              >
                Avanti
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AnagraficaCliente;
