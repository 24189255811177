import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Moment from 'react-moment';
import IconePartecipante from './IconePartecipante';
import SelettoreGruppoPartecipanti from '../modal/SelettoreGruppoPartecipanti';
import { FaPhoneAlt as PhoneIcon } from 'react-icons/fa';

function ListaPartecipanti({ filteredList, AttivaFirmaCliente, eventCode }) {
  return (
    <>
      {filteredList.map((partecipante) => {
        return (
          <div
            className={`card border-0 text-dark my-3 position-relative overflow-hidden ${
              partecipante.stato === 'confermato' ? 'bg-success text-white' : ''
            }`}
            style={{ borderRadius: '20px' }}
            key={partecipante.id}
          >
            <div className={`card-body`}>
              <h5 className="m-0">
                {partecipante.stato === 'iscritto' ? (
                  <a
                    className="text-decoration-none"
                    style={{ color: 'black' }}
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target={`#scannerQrCodePartecipante`}
                    aria-controls={`scannerQrCodePartecipante`}
                    onClick={() =>
                      AttivaFirmaCliente(partecipante.codiceQRCode)
                    }
                  >
                    {' '}
                    {partecipante.nome} {partecipante.cognome}
                    {partecipante.ragioneSociale && (
                      <span className="fw-light">
                        | {partecipante.ragioneSociale}
                        {'  '}
                      </span>
                    )}
                  </a>
                ) : (
                  <Link
                    className="text-decoration-none"
                    style={{ color: 'white' }}
                    to={`/anagrafica-partecipante-hostess/${partecipante.id}?eventCode=${eventCode}`}
                  >
                    {' '}
                    {partecipante.nome} {partecipante.cognome}
                    {partecipante.ragioneSociale && (
                      <span className="fw-light">
                        | {partecipante.ragioneSociale}
                      </span>
                    )}
                  </Link>
                )}
                <span
                  className={`fw-light ms-2 ${
                    partecipante.numeroTelefono ? '' : 'd-none'
                  }`}
                >
                  |{' '}
                  <a
                    className="text-decoration-none text-warning"
                    href={`tel:${partecipante.numeroTelefono}`}
                  >
                    <PhoneIcon></PhoneIcon>
                    {'  '}
                    {partecipante.numeroTelefono}
                  </a>
                </span>
              </h5>

              <div className="d-flex align-items-center my-2">
                <div
                  className="bg-white text-dark px-3 py-1 rounded"
                  style={{ marginRight: '1px', border: '1px black solid' }}
                >
                  {partecipante.sessione.nome}
                </div>
                <IconePartecipante partecipante={partecipante} />
              </div>
            </div>

            <div
              className="bordo-colore-gruppo-partecipante"
              data-bs-target={`#modalSelettoreGruppoPartecipanti-${partecipante.id}`}
              data-bs-toggle="modal"
              style={{
                cursor: 'pointer',
                background: `${
                  partecipante.gruppo && partecipante.gruppo.colore
                    ? partecipante.gruppo.colore
                    : '#D3D3D3'
                }`,
              }}
            ></div>
            <SelettoreGruppoPartecipanti partecipante={partecipante} />
          </div>
        );
      })}
    </>
  );
}

export default ListaPartecipanti;
