import React, { useContext, useEffect, useState } from 'react';
import '../App.css';
import axios from 'axios';
import useAuth from '../UseAuth';
import { StateContext } from '../components/StateContainer';
import Cookies from 'js-cookie';
function DispatchingPage() {
  const { setLoading, setPageTitle, setEventDetails } =
    useContext(StateContext);

  const [isError, setIsError] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login } = useAuth();

  useEffect(() => {
    setPageTitle('Login');
  }, []);

  const loginUrl = new URL(window.location.href);
  const eventCode = loginUrl.searchParams.get('eventCode');
  const autologinCode = loginUrl.searchParams.get('autologinCode');

  useEffect(() => {
    console.log(eventCode);
    console.log(autologinCode);
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_PUBLIC_URL}/user/detail`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: {
        eventCode: eventCode,
        autologinCode: autologinCode,
      },
    })
      .then((response) => {
        const eventDetails = response.data.response;
        Cookies.set('eventDetails', JSON.stringify(eventDetails), {
          expires: 7,
        });
        console.log(response.data.response);
        setEventDetails(eventDetails);

        if (
          response.data.response.redirectRoute === '/login' ||
          response.data.response.redirectRoute === '/registrazione'
        ) {
          window.location.replace(
            response.data.response.redirectRoute +
              `?eventCode=${eventCode}&autologinCode=${autologinCode}`
          );
        } else {
          login(response.data.response.redirectRoute);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleSubmit = (event) => {
    setIsError('');
    event.preventDefault();
    setLoading(true);
    const loginPayload = {
      email: email,
      password: password,
    };

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/login_check`, loginPayload)
      .then(
        (response) => {
          setLoading(false);
          setIsError('');
          const token = response.data.token;
          login(token);
        },
        (reason) => {
          setLoading(false);
          setIsError(true);
          console.error(reason);
          if (reason.response.data.code === 401) {
            setIsError('Email o password errati');
          } else {
            setIsError("C'è stato un errore nel server. Riprovare più tardi.");
          }
        }
      );
  };

  return (
    <div
      className="account-pages bg-black"
      style={{
        height: 'auto',
        minHeight: '100vh',
      }}
    >
      <div
        className="container-fluid d-flex align-items-center justify-content-center"
        style={{
          height: 'auto',
          minHeight: '100vh',
        }}
      >
        <div className="row ">
          <div className="col-12 text-center">
            <div class="d-flex justify-content-center">
              <div
                class="spinner-border text-white"
                role="status"
                style={{ height: '100px', width: '100px' }}
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div className="container"></div>
    </div>
  );
}

export default DispatchingPage;
