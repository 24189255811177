import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { StateContext } from '../../../components/StateContainer';
import SelezionaSessione from '../modal/SelezionaSessione';
import SelezionaGruppo from '../modal/SelezionaGruppo';
import { FaFilter as Filtro } from 'react-icons/fa';
import { SiSessionize as IconSessione } from 'react-icons/si';
import { BiSolidBriefcaseAlt as Valigia } from 'react-icons/bi';
import { FaUserGroup as IconaGruppi } from 'react-icons/fa6';
import { FaBed as IconaPernottamento } from 'react-icons/fa';
import { ImKey2 as IconaTestDrive } from 'react-icons/im';
import { FaWheatAwnCircleExclamation as IconaIntolleranze } from 'react-icons/fa6';
import { IoRestaurant as Cena } from 'react-icons/io5';
import { BsFillCarFrontFill as Car } from 'react-icons/bs';
import { FaPlane as Plane } from 'react-icons/fa';
import { FaTrainSubway as Train } from 'react-icons/fa6';
import { IoCloseOutline as Close } from 'react-icons/io5';

function Filtri() {
  const { setSearchParams, params, setParams, searchParams } =
    useContext(StateContext);

  const { idSessione, idPartecipante } = useParams();
  const [selectedSession, setSelectedSession] = useState('');
  const [sessionId, setSessionId] = useState(null);
  const [sessionName, setSessionName] = useState('Seleziona Sessione');
  const [gruppoId, setGruppoId] = useState(null);
  const [gruppoSelected, setGruppoSelected] = useState({
    text: 'Seleziona Gruppo',
    colore: '',
  });

  // Stato temporaneo per i filtri
  const [tempParams, setTempParams] = useState(params);

  const toggleTempParam = (key, value) => {
    setTempParams((prev) => {
      const newParams = { ...prev };

      if (newParams[key] === value) {
        delete newParams[key];
      } else {
        newParams[key] = value;
      }

      return newParams;
    });
  };

  console.log(tempParams);

  const handleResetFilters = () => {
    setSessionId(null);
    setSessionName('Seleziona Sessione');
    setGruppoId(null);
    setGruppoSelected({
      text: 'Seleziona Gruppo',
      colore: '',
    });
    // Ottiene i parametri attuali dalla URL
    const urlParams = new URLSearchParams(window.location.search);

    // Mantiene solo eventCode, se presente
    const eventCode = urlParams.get('eventCode');
    urlParams.forEach((_, key) => {
      if (key !== 'eventCode') {
        urlParams.delete(key);
      }
    });

    // Aggiorna la URL con solo eventCode
    window.history.pushState(null, '', '?' + urlParams.toString());

    // Aggiorna lo stato dei filtri
    const newParams = eventCode ? { eventCode } : {};

    setTempParams(newParams);
    setParams(newParams);
    setSearchParams(new URLSearchParams(newParams));

    const openFiltersButton = document.getElementById('button-open-filters');
    if (openFiltersButton) {
      openFiltersButton.click();
    }
  };

  // Applica i filtri quando si clicca su "Cerca"
  const handleFiltraClick = () => {
    setParams(tempParams);
    setSearchParams(new URLSearchParams(tempParams));
    console.log('Filtri applicati:', tempParams);
    const openFiltersButton = document.getElementById('button-open-filters');
    if (openFiltersButton) {
      openFiltersButton.click();
    }
  };

  const handleSessionReset = (key, value) => {
    setSessionId(null);
    setSessionName('Seleziona Sessione');

    // Rimuove il parametro sessione dalla URL
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.delete('sessione');
    window.history.pushState(null, '', '?' + urlParams.toString());

    setTempParams((prev) => {
      const newParams = { ...prev };

      if (newParams[key] === value) {
        delete newParams[key];
      } else {
        newParams[key] = value;
      }

      // Rimuove il parametro "sessione" se esiste
      delete newParams['sessione'];

      return newParams;
    });
  };

  const handleGroupReset = (key, value) => {
    setGruppoId(null);
    setGruppoSelected({
      text: 'Seleziona Gruppo',
      colore: '',
    });

    // Rimuove il parametro sessione dalla URL
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.delete('gruppo');
    window.history.pushState(null, '', '?' + urlParams.toString());

    setTempParams((prev) => {
      const newParams = { ...prev };

      if (newParams[key] === value) {
        delete newParams[key];
      } else {
        newParams[key] = value;
      }

      // Rimuove il parametro "sessione" se esiste
      delete newParams['gruppo'];

      return newParams;
    });
  };

  return (
    <div className="accordion" id="accordionPanelsStayOpenExample">
      <div
        className="accordion-item"
        style={{ borderRadius: '10px', overflow: 'hidden' }}
      >
        <h2
          className="accordion-header custom-accordion"
          id="panelsStayOpen-headingOne"
        >
          <button
            className="accordion-button d-flex justify-content-between collapsed menu-filter-collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#panelsStayOpen-collapseOne"
            aria-expanded="false"
            id="button-open-filters"
          >
            <span className="text-white d-flex align-items-center gap-2 font-size-20">
              <Filtro /> Filtri
            </span>
            <span className="contatore-filtri-attivi">
              {
                Object.keys(tempParams).filter((key) => key !== 'eventCode')
                  .length
              }
            </span>
          </button>
        </h2>
        <div
          id="panelsStayOpen-collapseOne"
          className="accordion-collapse collapse "
          aria-labelledby="panelsStayOpen-headingOne"
        >
          <div className="accordion-body p-0">
            {/* Inizio accordion filtri  */}
            {/* Accordion Sessioni  */}
            <div className="accordion" id="listaAFiltriAccordion">
              <div className="accordion-item bg-white">
                <h2
                  className="accordion-header"
                  id="listaAFiltriAccordion-headingOne"
                >
                  <button
                    className="accordion-button collapsed menu-custom-accordion-button py-2"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#listaAFiltriAccordion-collapseOne"
                    aria-expanded="false"
                    aria-controls="listaAFiltriAccordion-collapseOne"
                  >
                    <span
                      className={`d-flex align-items-center gap-2  ${
                        tempParams.sessione ? 'text-success' : ''
                      }`}
                    >
                      <IconSessione className="fs-5" /> Sessioni
                    </span>
                  </button>
                </h2>
                <div
                  id="listaAFiltriAccordion-collapseOne"
                  className="accordion-collapse collapse"
                  aria-labelledby="listaAFiltriAccordion-headingOne"
                >
                  <div className="accordion-body d-flex align-items-center">
                    <a
                      className="btn bottone-select-grigio rounded-pill "
                      data-bs-toggle="modal"
                      data-bs-target="#modalSelezionaSessione"
                      style={{ cursor: 'pointer' }}
                    >
                      {sessionName}
                    </a>
                    {sessionId && (
                      <div
                        onClick={() =>
                          handleSessionReset('sessione', sessionId)
                        }
                        className="ms-2 close-icon d-flex align-items-center justify-content-center"
                        style={{ cursor: 'pointer' }}
                      >
                        <Close />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Accordion Gruppi  */}
              <div className="accordion-item bg-white">
                <h2
                  className="accordion-header"
                  id="listaAFiltriAccordion-headingGroup"
                >
                  <button
                    className="accordion-button collapsed menu-custom-accordion-button py-2"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#listaAFiltriAccordion-collapseGroup"
                    aria-expanded="false"
                    aria-controls="listaAFiltriAccordion-collapseGroup"
                  >
                    <span
                      className={`d-flex align-items-center gap-2  ${
                        tempParams.gruppo ? 'text-success' : ''
                      }`}
                    >
                      <IconaGruppi className="fs-5" />
                      Gruppi
                    </span>
                  </button>
                </h2>
                <div
                  id="listaAFiltriAccordion-collapseGroup"
                  className="accordion-collapse collapse"
                  aria-labelledby="listaAFiltriAccordion-headingOne"
                >
                  <div className="accordion-body d-flex align-items-center">
                    <a
                      className="btn bottone-select-grigio rounded-pill position-relative bottone-gruppo-selezionato"
                      data-bs-toggle="modal"
                      data-bs-target="#modalSelezionaGruppo"
                      style={{ cursor: 'pointer' }}
                    >
                      <div
                        className="show-color-group-selected"
                        style={{
                          backgroundColor: `${gruppoSelected.colore}`,
                        }}
                      ></div>
                      <div
                        className="text-start me-2"
                        style={{ marginLeft: '30px' }}
                      >
                        {gruppoSelected.text}
                      </div>
                    </a>
                    {gruppoId && (
                      <div
                        onClick={() => handleGroupReset('gruppo', gruppoId)}
                        className="ms-2 close-icon d-flex align-items-center justify-content-center"
                        style={{ cursor: 'pointer' }}
                      >
                        <Close />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Mezzi di trasporto  */}
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="listaAFiltriAccordion-headingThree"
                >
                  <button
                    className="accordion-button collapsed menu-custom-accordion-button py-2"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#listaAFiltriAccordion-collapseThree"
                    aria-expanded="false"
                    aria-controls="listaAFiltriAccordion-collapseThree"
                  >
                    <span
                      className={`d-flex align-items-center gap-2 ${
                        tempParams.mezzoTrasportoAereo ||
                        tempParams.mezzoTrasportoTreno ||
                        tempParams.mezzoTrasportoMacchina
                          ? 'text-success'
                          : ''
                      }`}
                    >
                      <Valigia className="fs-5" /> Mezzo di Trasporto
                    </span>
                  </button>
                </h2>
                <div
                  id="listaAFiltriAccordion-collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="listaAFiltriAccordion-headingThree"
                >
                  <div className="accordion-body d-flex align-items-center gap-4">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="mezzoTrasportoMacchina"
                        checked={tempParams.mezzoTrasportoMacchina === 'true'}
                        onChange={(e) =>
                          toggleTempParam('mezzoTrasportoMacchina', 'true', e)
                        }
                      />
                      <label
                        class="form-check-label d-flex align-items-center gap-1"
                        for="mezzoTrasportoMacchina"
                      >
                        <Car className="fs-5" />
                        Macchina
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="mezzoTrasportoAereo"
                        checked={tempParams.mezzoTrasportoAereo === 'true'}
                        onChange={(e) =>
                          toggleTempParam('mezzoTrasportoAereo', 'true', e)
                        }
                      />
                      <label
                        class="form-check-label d-flex align-items-center gap-1"
                        for="mezzoTrasportoAereo"
                      >
                        <Plane className="fs-5" /> Aereo
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="mezzoTrasportoTreno"
                        checked={tempParams.mezzoTrasportoTreno === 'true'}
                        onChange={(e) =>
                          toggleTempParam('mezzoTrasportoTreno', 'true', e)
                        }
                      />
                      <label
                        class="form-check-label d-flex align-items-center gap-1"
                        for="mezzoTrasportoTreno"
                      >
                        <Train className="fs-5" /> Treno
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              {/* Cena  */}
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="listaAFiltriAccordion-headingFour"
                >
                  <button
                    className={`accordion-button collapsed menu-custom-accordion-button py-2`}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#listaAFiltriAccordion-collapseFour"
                    aria-expanded="false"
                    aria-controls="listaAFiltriAccordion-collapseFour"
                  >
                    <span
                      className={`d-flex align-items-center gap-2  ${
                        tempParams.cenaSi || tempParams.cenaNo
                          ? 'text-success'
                          : ''
                      }`}
                    >
                      <Cena className="fs-5" /> Cena
                    </span>
                  </button>
                </h2>
                <div
                  id="listaAFiltriAccordion-collapseFour"
                  className="accordion-collapse collapse"
                  aria-labelledby="listaAFiltriAccordion-headingFour"
                >
                  <div className="accordion-body d-flex align-items-center gap-4">
                    <div class="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="cenaSi"
                        checked={tempParams.cenaSi === 'true'}
                        onChange={(e) => toggleTempParam('cenaSi', 'true', e)}
                      />
                      <label class="form-check-label" for="cenaSi">
                        Sì
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="cenaNo"
                        checked={tempParams.cenaNo === 'true'}
                        onChange={(e) => toggleTempParam('cenaNo', 'true', e)}
                      />
                      <label class="form-check-label" for="cenaNo">
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              {/* Pernottamento  */}
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="listaAFiltriAccordion-pernottamento"
                >
                  <button
                    className="accordion-button collapsed menu-custom-accordion-button py-2"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#listaAFiltriAccordion-collapsePernottamento"
                    aria-expanded="false"
                    aria-controls="listaAFiltriAccordion-collapsePernottamento"
                  >
                    <span
                      className={`d-flex align-items-center gap-2 ${
                        tempParams.pernottamentoSi || tempParams.pernottamentoNo
                          ? 'text-success'
                          : ''
                      }`}
                    >
                      <IconaPernottamento className="fs-5" />
                      Pernottamento
                    </span>
                  </button>
                </h2>
                <div
                  id="listaAFiltriAccordion-collapsePernottamento"
                  className="accordion-collapse collapse"
                  aria-labelledby="listaAFiltriAccordion-pernottamento"
                >
                  <div className="accordion-body d-flex align-items-center gap-4">
                    <div class="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="pernottamentoSi"
                        checked={tempParams.pernottamentoSi === 'true'}
                        onChange={(e) =>
                          toggleTempParam('pernottamentoSi', 'true', e)
                        }
                      />
                      <label class="form-check-label" for="pernottamentoSi">
                        Sì
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="pernottamentoNo"
                        checked={tempParams.pernottamentoNo === 'true'}
                        onChange={(e) =>
                          toggleTempParam('pernottamentoNo', 'true', e)
                        }
                      />
                      <label class="form-check-label" for="pernottamentoNo">
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              {/* Intolleranze  */}
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="listaAFiltriAccordion-intolleranze"
                >
                  <button
                    className="accordion-button collapsed menu-custom-accordion-button py-2"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#listaAFiltriAccordion-collapseIntolleranze"
                    aria-expanded="false"
                    aria-controls="listaAFiltriAccordion-collapseIntolleranze"
                  >
                    <span
                      className={`d-flex align-items-center gap-2  ${
                        tempParams.intolleranzaSi || tempParams.intolleranzaNo
                          ? 'text-success'
                          : ''
                      }`}
                    >
                      <IconaIntolleranze className="fs-5" /> Intolleranze
                    </span>
                  </button>
                </h2>
                <div
                  id="listaAFiltriAccordion-collapseIntolleranze"
                  className="accordion-collapse collapse"
                  aria-labelledby="listaAFiltriAccordion-intolleranze"
                >
                  <div className="accordion-body d-flex align-items-center gap-4">
                    <div class="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="intolleranzaSi"
                        checked={tempParams.intolleranzaSi === 'true'}
                        onChange={(e) =>
                          toggleTempParam('intolleranzaSi', 'true', e)
                        }
                      />
                      <label class="form-check-label" for="intolleranzaSi">
                        Sì
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="intolleranzaNo"
                        checked={tempParams.intolleranzaNo === 'true'}
                        onChange={(e) =>
                          toggleTempParam('intolleranzaNo', 'true', e)
                        }
                      />
                      <label class="form-check-label" for="intolleranzaNo">
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              {/* Test Drive  */}
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="listaAFiltriAccordion-TestDrive"
                >
                  <button
                    className="accordion-button collapsed menu-custom-accordion-button py-2"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#listaAFiltriAccordion-collapseTestDrive"
                    aria-expanded="false"
                    aria-controls="listaAFiltriAccordion-collapseTestDrive"
                  >
                    <span
                      className={`d-flex align-items-center gap-2 ${
                        tempParams.testDriveSi || tempParams.testDriveNo
                          ? 'text-success'
                          : ''
                      }`}
                    >
                      <IconaTestDrive className={`fs-5`} />
                      Test Drive
                    </span>
                  </button>
                </h2>
                <div
                  id="listaAFiltriAccordion-collapseTestDrive"
                  className="accordion-collapse collapse"
                  aria-labelledby="listaAFiltriAccordion-TestDrive"
                >
                  <div className="accordion-body d-flex align-items-center gap-4">
                    <div class="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="testDriveSi"
                        checked={tempParams.testDriveSi === 'true'}
                        onChange={(e) =>
                          toggleTempParam('testDriveSi', 'true', e)
                        }
                      />
                      <label class="form-check-label" for="testDriveSi">
                        Sì
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="testDriveNo"
                        checked={tempParams.testDriveNo === 'true'}
                        onChange={(e) =>
                          toggleTempParam('testDriveNo', 'true', e)
                        }
                      />
                      <label class="form-check-label" for="testDriveNo">
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              {/* Fine Accordion Filtri  */}
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-center gap-2 m-2 py-2">
            <button className="btn btn-dark w-100" onClick={handleFiltraClick}>
              <Filtro /> Filtra
            </button>
            <button className="btn btn-dark w-100" onClick={handleResetFilters}>
              Annulla filtri
            </button>
          </div>
        </div>
      </div>
      <SelezionaSessione
        selectedSession={selectedSession}
        sessionId={sessionId}
        setSessionId={setSessionId}
        sessionName={sessionName}
        setSessionName={setSessionName}
        toggleTempParam={toggleTempParam}
      ></SelezionaSessione>
      <SelezionaGruppo
        selectedSession={selectedSession}
        tempParams={tempParams}
        params={params}
        gruppoId={gruppoId}
        setGruppoId={setGruppoId}
        gruppoSelected={gruppoSelected}
        setGruppoSelected={setGruppoSelected}
        toggleTempParam={toggleTempParam}
      ></SelezionaGruppo>
    </div>
  );
}

export default Filtri;
